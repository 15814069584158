import '../../css/report-card.css';
import React, {useState, useEffect, useRef} from "react";
import { CircularProgress } from '@mui/material';
import { Card, CardContent, Container, Grid, Paper, 
  MenuItem, Select, Typography } from '@mui/material';
import moment from 'moment';

import { AppHelper } from '../../AppHelper';
import AppTopBar from '../common/app-top-bar';
import { useEssayContext } from "../../contexts/context-essay";
import { useExerciseContext } from "../../contexts/context-exercise";
import ReportCardChart from "./report-card-chart";
import ReportCardChartPolar from "./report-card-chart-polar";
import ReportCardChartSpark from "./report-card-chart-spark";
// import { profile } from '../../../../nodeApi/models';
const dayjs = require('dayjs');

export default function ReportCardExercise() {
  const [oUser, setOUser] = useState(AppHelper.userCacheGet());
  
  const [loading, setLoading] = useState(true);
  const refTopBar = useRef(null);
  const [chartData, setChartData] = useState(null);
  const [chartDataPolar, setChartDataPolar] = useState(null);
  const [chartData1, setChartData1] = useState(null);
  const [chartData2, setChartData2] = useState(null);
  const [chartData3, setChartData3] = useState(null);
  const [chartData4, setChartData4] = useState(null);
  const [chartData5, setChartData5] = useState(null);
  const [chartData6, setChartData6] = useState(null);
  const {recList, setRecList } = useExerciseContext();
  const [exerciseListForReport, setExerciseListForReport] = useState(null);
  const [bestExercise, setBestExercise] = useState(null);
  const [profileName, setProfileName] = useState('');
  const todayTitle = moment().format(`DD MMM 'YY`);
  const [profiles, setProfiles] = React.useState([]);
  const [profileId, setProfileId] = useState('');

//   const categoryTitles = [
//     "Relevance & Logic", "Development", 
//     "Grammar", "Spelling", 
//     "Vocabulary", "Structure & Flow"
//   ]

  useEffect(() => {
    AppHelper.apiAuditPost("P-RC", "");
  }, []);
  useEffect(() => {
    if(oUser) setProfileName(oUser.name);
  }, [oUser]);
  useEffect(() => {
    if(!recList || recList.length==0) {
        // console.log("No record available");
        // setChartDataPolar(0);
        // setChartData(null);
        // setLoading(false);
        return;
    }
    setExerciseListForReport(recList);
    var _user = AppHelper.userCacheGet();
    if (_user && _user.grade) {
      setProfileId(_user.profileId);
      console.log("_user.profileId", _user.profileId);
      fetchProfiles(_user);
    //   fetchExerciseList(_user.profileId);
    // fetchScores();
    }
  }, [recList]);

  useEffect(()=>{
    if(!exerciseListForReport) return;
    fetchScores();
  }, [exerciseListForReport]);
    
  
  const fetchScores = async () => {
    try {
		  setLoading(true);
      var _iReport = 0;
      var _recExercise = [];
      var _chartData = [];
      var _exerciseArr = AppHelper.sortList(exerciseListForReport, 'tCode', 2);
      var _top7 = _exerciseArr.filter(x=>!x.isSample);//.slice(0,1);
      var availableExCount = _top7.length;
      var maxCount = 0;
      //_top7 = AppHelper.sortList(_top7, 'tCode', 1);
      //console.log('sorted list', _top7);
      if (availableExCount < 7){
        maxCount = availableExCount;
      } else {
        maxCount = 7;
      }
      var _bestExercise = {score: 0, exName:'', title: '', time: '', avgScore: 0, essayCount: 0};
      var _sumScore = 0;
      for(var i=0; i<maxCount; i++)
      {
        // if(_iReport<7){
          var _r = _top7[i];
          const _resExcDetail = await AppHelper.apiPost('api/exercise/sentenceTransform/get', {token: oUser.token, profileId, exerciseId: _r.exerciseId});
          console.log('_resExcDetail', _resExcDetail);

          if (_resExcDetail && _resExcDetail.status) {
            _recExercise.push(_resExcDetail.data);
            var _recScore = (_resExcDetail.data.questionYCount/_resExcDetail.data.questionCount)*100;
            if (_resExcDetail.data.questionYCount == 0 && _resExcDetail.data.questionCount == 0) {
                _recScore = 0;
            }
            // console.log('_resScore', _recScore);
            if(_recScore>_bestExercise.score){
                _bestExercise = {score: _recScore, exCount: _top7.length-i, exName: _r.questionSetName, time: _r.tTitle, avgScore: 0, essayCount: 0 };
                console.log('_bestExercise', _bestExercise);
            }
            _sumScore += _recScore;
            _chartData.push({
                title: _top7.length-i,
                score: _recScore,
                time: _r.tTitle,
                tCode: _r.tCode,
            });
            if(i==0){
                setChartDataPolar(_recScore);
            } else {
                console.log('score api error', _r.exerciseId, _resExcDetail);
            }
          }
          // console.log('_chartData', _chartData);
      }
      if(i<1){
        // setChartDataPolar([]);
      }
      _bestExercise.exerciseCount = i;// _top7.length;
      _bestExercise.avgScore = _sumScore == 0 ? 0 : Math.round(_sumScore/i);
      setBestExercise(_bestExercise);

      setChartData(_chartData.reverse());
      
      _recExercise.forEach(r => {
        // if(r.scoreResult && r.scoreResult.length>0)
        //   _chartData.push(Math.round(r.scoreResult[0].overall_score));
      });
      setLoading(false);
    } catch (error) {
      console.error('Error fetching score:', error);
    } finally {
		  setLoading(false); 
		}
  };

  const fetchExerciseList = async(argProfileId)=>{
    setLoading(true);
    var _user = AppHelper.userCacheGet();
    if (!_user) return;
    var _res = await AppHelper.apiPost('api/exercise/sentenceTransform/list', {token: _user.token, profileId: argProfileId});
    if(!_res.status){
      console.log('Error', _res.message);
      return;
    }
    var _arr = [..._res.data];
    _arr.forEach(x=>{
      x.time = dayjs(x.timestampUtc).format("DD MMM YY' hh:mm A");
      x.tTitle = dayjs(x.timestampUtc).format("DD MMM YY' hh:mm A");
      x.tCode = dayjs(x.timestampUtc).format("YYYYMMDDHHmmss");
    });
    _arr = AppHelper.sortList(_arr, 'tCode', 2);
    setExerciseListForReport(_arr);
    setLoading(false);
  }
  const fetchProfiles = async () => {
    if (!oUser) return;
    try {
    const response = await AppHelper.apiPost('api/profile/list', { token: oUser.token });
        if (response && response.status && response.data) {
        setProfiles(response.data);
        } else {
        throw new Error('Failed to fetch profiles');
        }
    } catch (error) {
        console.error('Error fetching profiles:', error);
    }
};
  const profileSelect = async (arg) => {
    var _profile = profiles.find(x=>x.profileId == arg.target.value);
    if(!_profile) return;
    setProfileName(_profile.name);
    setProfileId(arg.target.value);
    fetchExerciseList(arg.target.value);
  }

  return (
  <div>
    <AppTopBar ref={refTopBar}/>
    {setRecList &&
    <Grid container>
      <Grid item xs={12} textAlign={'right'}>
        {profiles && profiles.length > 1 && (
          <Select labelId='lblProfile'
            value={profileId}
            onChange={profileSelect}
            label=""
            sx={{ "& fieldset": { border: 'none' }}}
          >
            {profiles.map((item) => (
              <MenuItem key={item.profileId} value={item.profileId}>
                {item.name}
              </MenuItem>
            ))}
          </Select>
        )}
      </Grid>
    </Grid>
    }
    <Container component="main" maxWidth="sm" sx={{ mb: 0 }}>
      <Paper sx={{ 
        my: { 
          xs: 3, md: 5 }, p: { xs: 2, md: 3 }, minHeight:{md:'70vh', xs:'70vh'}, 
          //backgroundColor:'rgb(235, 217, 180)' 
        }} elevation={3}>
          <Grid container>
            <Grid item xs={6}>
              <div style={{marginBottom:0, fontSize:'1.8rem', fontWeight:'bold'}}>Report Card</div>
            </Grid>
            <Grid item xs={6} display={'flex'} alignItems={'flex-end'} justifyContent={'flex-start'} flexDirection={'column'}>
              <div style={{fontSize:'2rem', color:'rgba(19,130,117,1)', fontWeight:700, fontFamily:'Roboto", "Helvetica", "Arial", sans-serif' }}>
              EssayAce
              </div>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={6}>
              <div style={{fontSize:'1rem', marginLeft:'.2rem'}}>
                {oUser &&
                <div style={{fontWeight:'bold'}}>{profileName}</div>
                }
              </div>
            </Grid>
            <Grid item xs={6}>
              <div style={{fontSize:'.8rem', textAlign:'right'}}>
                {todayTitle}
              </div>
            </Grid>
          </Grid>
          { loading &&
            <div style={{margin:'2rem', textAlign:'center'}}>
              <CircularProgress />
            </div>
          }
          { !loading &&
          <>
          { bestExercise &&
          <Grid container sx={{marginBottom:'1rem', marginTop:'1rem'}}>
            <Grid item xs={12} marginBottom={'.5rem'}>
              <Card>
                <CardContent>
                  <Grid container>
                    <Grid item xs={5}>
                      <Typography variant="h5" component="div" style={{fontWeight:'bold', color:'rgba(19,130,117,1)'}}>
                        {bestExercise.score}%
                      </Typography>
                    </Grid>
                    <Grid item xs={7} textAlign={'right'}>
                      <Typography variant="body2" color="text.primary" fontWeight={'normal'}>
                        Best Exercise
                      </Typography>
                      <div style={{fontSize:'.8rem'}}>
                        {bestExercise.time}
                      </div>
                    </Grid>
                  </Grid>
                  <div>Exercise {bestExercise.exCount}</div>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12}>
              <Card>
                <CardContent>
                  <Grid container>
                    <Grid item xs={5}>
                      <Typography variant="h5" component="div" style={{fontWeight:'bold', color:'rgba(19,130,117,1)'}}>
                        {bestExercise.avgScore}%
                      </Typography>
                    </Grid>
                    <Grid item xs={7} textAlign={'right'}>
                      <Typography variant="body2" color="text.primary">
                        Avg. Score
                      </Typography>
                      {/* <div style={{fontSize:'.8rem'}}>
                        {bestExercise.time}
                      </div> */}
                    </Grid>
                  </Grid>
                  <div>Across {bestExercise.exerciseCount} exercise</div>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
          }
          <div>
            <ReportCardChart argData={chartData} />
          </div>
          </>
          }
      </Paper>
      {/* <AppFooterFeedback/> */}
    </Container>
    <div style={{height:0}}>&nbsp;</div>
  </div>
  );
}
