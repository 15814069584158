import React, { useEffect, useState } from "react";
import { Button, CircularProgress, Tooltip } from '@mui/material';
import '../../App.css';
import './sentence-transform-explainer.css';
import { AppHelper } from "../../AppHelper";
import CachedRoundedIcon from '@mui/icons-material/CachedRounded';

export default function SentenceTransformExplainer({oUser, question}) {
	const [record, setRecord] = useState({});
	const [originalData, setOriginalData] = useState(null);
	const [transformedData, setTransformedData] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const [isSa, setIsSa] = React.useState(false);
	const [isHovered, setIsHovered] = useState(false);
	const [autoIndex, setAutoIndex] = useState(-1);
	const [activeStepIdx, setActiveStepIdx] = useState(0);
	  
	useEffect(()=>{
		const fetchIAM = async()=>{
		var _res = await AppHelper.apiPost('api/account/iam', {token: oUser.token});
		if(!_res || !_res.status || !_res.data || !_res.data.isSa){
			return;
		}
		setIsSa(true);
		}
		fetchIAM();
	}, [oUser]);

  useEffect(() => {
		if(!question || !oUser) return;
		const fetchExplain = async (argQuestion)=>{
			setIsLoading(true);
			var _question = {...argQuestion};
			
			_question.question = fullAnsStr(_question.question, _question.correctedAnswer);	

			var _res = await AppHelper.apiPost('api/exercise/sentenceTransform/explain', 
				{ 
					token: oUser.token, 
					question: _question
				});
			setIsLoading(false);
			if(!_res.status){
				alert('Failed to fetch the data Explainer!');
				return;
			}
			if(_res.data.original_and_transformed){
				var _hpOriginal = _res.data.original_and_transformed.find(x=>x.version == 'original');
				if(_hpOriginal){
					if(_hpOriginal.highlighted_parts){
						_hpOriginal.highlighted_parts.map(x=>{
							x.textView = (x.text.match(/<b>(.*?)<\/b>/) || ["", ""])[1];
						});
					}
					_hpOriginal.full_sentenceView = _hpOriginal.full_sentence;
					setOriginalData(_hpOriginal);
				}
				var _hpTransformed = _res.data.original_and_transformed.find(x=>x.version == 'transformed');
				if(_hpTransformed){
					if(_hpTransformed.highlighted_parts){
						_hpTransformed.highlighted_parts.map(x=>{
							x.textView = (x.text.match(/<b>(.*?)<\/b>/) || ["", ""])[1];
						});
					}
					_hpTransformed.full_sentenceView = _hpTransformed.full_sentence;
					setTransformedData(_hpTransformed);
				}
			}
			_res.data.steps.forEach(x=>{
				x.description2 = x.description.replace(/(["'])(.*?)\1/g, (match, quote, content) => {
					return `${quote}<b>${content}</b>${quote}`;
				});
			});
			setRecord(_res.data);
			// console.log("setRecord", _res.data);
		};

		fetchExplain(question);
  }, [question, oUser]);

	const addTextHighlight = (highlightStr, fullStr)=>{
		const match = highlightStr.match(/<b>(.*?)<\/b>/);
		if (match && match[1]) {
		  	const textToHighlight = match[1];
			// set word boundaries to avoid "he" matching "t'he'"
			const regex = new RegExp(`\\b${textToHighlight}\\b`);
			console.log(regex);
			var returnStr = fullStr.replace(regex, `<span class="highlighted-part">${textToHighlight}</span>`);
			
			if (returnStr === fullStr) {// str include "," will not match the word boundaries. 
				returnStr =  fullStr.replace(textToHighlight, `<span class="highlighted-part">${textToHighlight}</span>`);
			}
		  	return returnStr;
		}
		return highlightStr.replaceAll('<b>', '<span class="highlighted-part">')
		 					.replaceAll('</b>', '</span>');

		// arg.textView = arg.text2.replaceAll('<b>', '<span class="highlighted-part">')
		// 								.replaceAll('</b>', '</span>');
	};
	const stepHighlight = (argIndex)=>{
		var _hpOriginal = record.steps[argIndex].highlight_original;
		var _fullOriginal = record.original_and_transformed[0].full_sentence; // index [0] follow the return data structure of the API
		setOriginalData({...originalData, full_sentenceView: addTextHighlight(_hpOriginal, _fullOriginal)});
		var _hpTransformed = record.steps[argIndex].highlight_transformed;
		var _fullTransformed = record.original_and_transformed[1].full_sentence; // index following the return data structure of the API
		setTransformedData({...transformedData, full_sentenceView: addTextHighlight(_hpTransformed, _fullTransformed)});
	}
	const stepHighlightReset = (argIndex)=>{
		setOriginalData({...originalData, full_sentenceView: originalData.full_sentence});
		setTransformedData({...transformedData, full_sentenceView: transformedData.full_sentence});
	}

	const handleReload = async(argQuestion) => {
		setIsLoading(true);
		var _question = {...argQuestion};
		_question.question = _question.correctedAnswer;
		var _res = await AppHelper.apiPost('api/exercise/sentenceTransform/explain/v2', 
			{ 
				token: oUser.token, 
				question: _question
			});
		setIsLoading(false);
		if(!_res.status){
			alert('Failed to fetch the data Explainer!');
			return;
		}
		if(_res.data.original_and_transformed){
			var _hpOriginal = _res.data.original_and_transformed.find(x=>x.version == 'original');
			if(_hpOriginal){
				if(_hpOriginal.highlighted_parts){
					_hpOriginal.highlighted_parts.map(x=>{
						x.textView = (x.text.match(/<b>(.*?)<\/b>/) || ["", ""])[1];
					});
				}
				_hpOriginal.full_sentenceView = _hpOriginal.full_sentence;
				setOriginalData(_hpOriginal);
			}
			var _hpTransformed = _res.data.original_and_transformed.find(x=>x.version == 'transformed');
			if(_hpTransformed){
				if(_hpTransformed.highlighted_parts){
					_hpTransformed.highlighted_parts.map(x=>{
						x.textView = (x.text.match(/<b>(.*?)<\/b>/) || ["", ""])[1];
					});
				}
				_hpTransformed.full_sentenceView = _hpTransformed.full_sentence;
				setTransformedData(_hpTransformed);
			}
		}
		_res.data.steps.forEach(x=>{
			x.description2 = x.description.replace(/(["'])(.*?)\1/g, (match, quote, content) => {
				return `${quote}<b>${content}</b>${quote}`;
			});
		});
		setRecord(_res.data);
	}

	const fullAnsStr = (questionStr, ansStr) => {
		const answers = [];
        const regexA = /<a>(.*?)<\/a>/g;
        let match;
        while ((match = regexA.exec(ansStr)) !== null) {
          answers.push(match[1]);
        }
        let answerIndex = 0;
		const fullStr = questionStr.replace(/<u>.*?<\/u>/g, () => {
			const replacement = `<u>${answers[answerIndex] || ''}</u>`;
			answerIndex++;
			return replacement;
		});
		return fullStr;
	}

	useEffect(() => {
		if (!isHovered && record.steps && record.steps.length > 0 &&
			originalData && transformedData) {
			  const timer = setInterval(() => {
				setAutoIndex((prevIndex) => {
					const newIndex = (prevIndex + 1) % record.steps.length;	
					stepHighlight(newIndex);		
					return newIndex;
				});

			  }, 3000);
			  return () => clearInterval(timer);
		}
	}, [isHovered, record, originalData, transformedData]);

	return(
		<>
		{!isLoading && record &&
		
		<div id="output">
			<div className="slide">
				{isSa &&
					<div className='button'>
					<Tooltip title="Try another explanation">
						<Button 
							onClick={() => handleReload(question)}
							>
							<CachedRoundedIcon />
						</Button>
					</Tooltip>
					</div> 
				}
				<div className="header-section">
					<h2>Transformation Example</h2>
					<h3>Showing Original and Transformed Sentences</h3>
				</div>
				<div className="sentence-container">
					<div className="sentence-box">
						{originalData &&
						<p dangerouslySetInnerHTML={{ __html:originalData.full_sentenceView}}></p>
						}
					</div>
					<div className="sentence-box">
						{transformedData &&
						<p dangerouslySetInnerHTML={{ __html:transformedData.full_sentenceView}}></p>
						}
					</div>
				</div>
				<table>
					<thead>
						<tr>
							<th className="explainer-th"  style={{width:'50%'}}>Original</th>
							<th className="explainer-th">Transformed</th>
						</tr>
					</thead>
					<tbody>
						{ originalData && originalData.highlighted_parts && originalData.highlighted_parts.map((hp, iHp)=>(
						<tr key={'tr01_' + iHp}>
							<td className="explainer-td"  >
								<div dangerouslySetInnerHTML={{ __html: hp.textView }}></div>
							</td>
							{ transformedData && transformedData.highlighted_parts && transformedData.highlighted_parts[iHp] &&
							<td className="explainer-td">
								<div dangerouslySetInnerHTML={{ __html: transformedData.highlighted_parts[iHp].textView }}></div>
							</td>
							}
						</tr>
						)) }
					</tbody>
				</table>
				<div className="speech">
					<h3>Steps to Understand the Transformation:</h3>
					{ record && record.steps && record.steps.map((s, iS)=>(
					<div key={'boxStep_'+iS} 
						 onMouseEnter={()=>{stepHighlight(iS);
									   setIsHovered(true);
									   setAutoIndex(iS);}} 
						 onMouseLeave={()=>{stepHighlight(autoIndex);
									   setIsHovered(false);
									   setAutoIndex(autoIndex);}} 
						className="step">
						<a className="explain-step" 
						   style={{ fontWeight: iS === autoIndex ? "bold" : "normal"}}
						   dangerouslySetInnerHTML={{ __html: (iS+1) + '. ' + s.description2}}>
						</a>
					</div>
					))}
				</div>
			</div>
		</div>
		}
		{ isLoading &&
		<div style={{margin:'1rem'}}>
			<CircularProgress />
		</div>
		}
		</>
	)
}