import React, { useState, useEffect, useLayoutEffect, useRef } from "react";
import { visuallyHidden } from "@mui/utils";
import { EssayHelper } from "../../helpers/essay.helper";
import { useNavigate } from 'react-router-dom';
//import { makeStyles } from '@mui/styles';
import AppTopBar from '../common/app-top-bar';
import AppLoading from '../common/app-loading';
import '../../css/property-list.css';
import {
  Chip, Dialog, DialogTitle, FormLabel, FormControlLabel, Paper, Button, Divider,
  Radio, RadioGroup, Select, Typography, MenuItem, FormControl, InputLabel, TextField, Grid, TableHead, TableBody, TableCell, TableContainer, TableSortLabel, TableRow, Table,
  CircularProgress
} from '@mui/material';
import Box from '@mui/material/Box';
import Snackbar from '@mui/material/Snackbar';
import { useProperty } from '../../contexts/context-property';
import { AppHelper } from '../../AppHelper';
import LoadingButton from '@mui/lab/LoadingButton';
import AssignmentIcon from '@mui/icons-material/Assignment';
import ToolbarPlugin from '../plugin/editor-toolbar';
import PageQuestion from '.././question';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCellQuestion = [
 
  { id: "grade", numeric: false, disablePadding: false, label: "GRADE", align: "" },
  { id: "uploadType", numeric: false, disablePadding: false, label: "OWNERSHIP", align: "" },
  { id: "title", numeric: false, disablePadding: false, label: "TITLE", align: "" },  
  { id: "topic", numeric: false, disablePadding: false, label: "CONTENT", align: "" },
];

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {	  
    onRequestSort(event, property);
  };
	
  console.log("props.gid", props.gid);
  const headCells = headCellQuestion;

  return (
    <TableHead>
      <TableRow>
        {/* <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'select all desserts',
            }}
          />
        </TableCell> */}

        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.align || headCell.numeric ? "center" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default function PopQuestion({
  onClose, open, onSubmit
}) {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const oUser = AppHelper.userCacheGet();
  const [msgBox, setMsgBox] = React.useState({
    open: false, vertical: 'top', horizontal: 'center', msg: ''
  });
  const [questionData, setQuestionData] = useState({
    text: '',
    files: [],
    question: '',
  });
  
  
  const [tblOrderQuestion, setTblOrderQuestion] = React.useState("asc");
  const [tblOrderByQuestion, setTblOrderByQuestion] = React.useState("title");
  const [questionList, setQuestionList] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);  
  const [txtNameOK, setTxtNameOK] = useState(true);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [authOK, setAuthOK] = React.useState(false);
  const questionTypes = [
    { code: '-', name: '-- Select --' },
    { code: 'BUG', name: 'BUG REPORT' },
    { code: 'FEATURE', name: 'FEATURE REQUEST' },
    { code: 'GENERAL', name: 'GENERAL QUESTION' },
  ];
  const [question, setQuestion] = useState('');
  const [filterText, setFilterText] = useState("");
  const [filteredQuestions, setFilteredQuestions] = useState([]);
  const [popQuestionOpen, setPopQuestionOpen] = useState(false);
  const [refreshList, setRefreshList] = React.useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [submitted, setSubmitted] = useState(false);
  
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  
  const listSortAndfilterQuestion = () => {
    var _list = [...questionList];
	var searchText = filterText || '';
	
    if (questionList && questionList.length > 0) {
		
       _list = _list.filter(
        (r) =>
          r.title.toLowerCase().includes(searchText) ||
          r.grade.toLowerCase().includes(searchText)
      );
    }

	setPage(0);

    return stableSort(_list, getComparator(tblOrderQuestion, tblOrderByQuestion));
  };
  
  const visibleQuestions = React.useMemo(
    () => listSortAndfilterQuestion(),

    [questionList, tblOrderQuestion, tblOrderByQuestion, filterText]
  );

  const fetchFilteredQuestions = async (searchText) => {    
      setFilterText(searchText);        
  };
  
  const tblSortQuestion = (event, property) => {	
    const isAsc = tblOrderByQuestion === property && tblOrderQuestion === "asc";
	
    setTblOrderQuestion(isAsc ? "desc" : "asc");
    setTblOrderByQuestion(property);
  };


  const fetchQuestions = async () => {
    setIsLoading(true);
    var _res = await AppHelper.apiPost('api/question/list/all', { token: oUser.token, includeImage:1 });
    if (!_res || !_res.status) {
      //alert("Fail to get question");
      console.log('ERROR', _res);
      return;
    }

    _res.data.forEach(x => {
      x.words = x.topic.split(' ');
      x.isLongText = x.words.length > 10;
      x.isExpanded = false;
    });
    console.log('list', _res.data);
    setQuestionList(_res.data);
    setFilteredQuestions(_res.data);
    setRefreshList(false);
    setIsLoading(false);
  };

  const questionSelect = async (arg) => {
    setQuestion(arg.target.value);
  };
  
  const onAddQuestion = () => {
    setPopQuestionOpen(true);
  }

  const popQuestionClose = () => {
    setRefreshList(true);
    setPopQuestionOpen(false);
  }

  useEffect(() => {
    if (submitted === true) {
      fetchQuestions();
    }
    setSubmitted(false);
  }, [submitted]);

  useEffect(() => {
    var _user = AppHelper.userCacheGet();
    setAuthOK(_user != null);

    if (_user) {

      fetchQuestions();
    }
  }, []);

  const handleSubmit = async (argQuestionId) => {
    //await handleQuestionSubmit();
    var _q = questionList.find(r => r.questionId == argQuestionId);
    if (!_q) {
      _q = -1;
      // alert('Invalid Question');
      // return;
    }
	
    if (onSubmit) {
      onSubmit(_q);
      onClose(_q.questionId ? _q.questionId : -1);
    }
    else onClose(question == '' ? -1 : question);
  };

  const handleQuestionSubmit = async () => {
    // if (question == '') {
    //   alert("Select a question");
    //   return;
    // }
    var _q = questionList.find(r => r.questionId == question);
    if (!_q) {
      _q = -1;
      // alert('Invalid Question');
      // return;
    }
    if (onSubmit) {
      console.log('question', _q);
      onSubmit(_q);
    }
    else onClose(question == '' ? -1 : question);
  };

  const handleClose = () => { onClose() };

  //const editor = useLexicalEditor(editorConfig);
  const [textContent, setTextContent] = useState('');

  //const editorInstance = useLexicalEditor(editorConfig);
  // const updateTextContent = () => {
  //   // Get the editor state and extract the text content
  //   editorRef.current.getEditorState().read(() => {
  //       const root = editorRef.current.getEditorState().read();
  //       const text = root.getTextContent();
  //       setTextContent(text);
  //     });
  // };
  const toggleExpanded = (arg) => {
    var _list = [...filteredQuestions];
    var _rec = _list.find(x => x.questionId == arg.questionId);
    if (!_rec) return;
    _rec.isExpanded = !_rec.isExpanded;
    setFilteredQuestions(_list);
  }
  return (
    <>
      <Dialog onClose={handleClose} open={open} fullWidth
        maxWidth={false}
        sx={{ '& .MuiDialog-paper': { width: '75%', maxWidth: 'none' } }}
      >
        <DialogTitle>{"SELECT QUESTION"}</DialogTitle>
        <Box pl={2} pr={2} pb={2} alignItems="flex-start">
          <Grid container>
            <Grid item xs={12}>
              <Box style={{ textAlign: 'center' }}>
                <LoadingButton
				  className="app-btn-bg"
					sx={{
					color: "white", // Change text color to white
				  }}
                  onClick={() => handleSubmit('')} // Pass questionId to handleSubmit
                  loading={isSubmitting}
                  variant="outlined"
                >
                  <span>Submit without question</span>
                </LoadingButton>
              </Box>


                    <Grid item xs={6} sm={6}>
                      {true &&
                        <Button
                          onClick={() => onAddQuestion()}
                          className="app-btn-bg"
                          color="info"
                          variant="contained"
                          size="large"
                          style={{ marginRight: '2px', marginBottom: '8px' }} // Adjust the marginBottom value as needed	  
                        >
                          ADD QUESTION
                        </Button>
                      }
                    </Grid>

              <Box m={0.5}>
                <TextField
                  fullWidth
                  variant="outlined"
                  label="Filter questions"
                  onChange={(e) => fetchFilteredQuestions(e.target.value)}
                  value={filterText}
                />
              </Box>
              <Box m={0.5}>
                <TableContainer style={{ maxHeight: 400, overflowY: 'auto' }}
                  component={Paper}
                  className="scrollbar-001"
                >
                  <Table stickyHeader>
                   
					
					<EnhancedTableHead
                      //numSelected={selected.length}
                      order={tblOrderQuestion}
                      orderBy={tblOrderByQuestion}
                      //onSelectAllClick={handleSelectAllClick}
                      onRequestSort={tblSortQuestion}
                      rowCount={visibleQuestions.length}
                      gid="G1"
                    />
					
                    <TableBody>
                      {isLoading &&
                        <TableRow>
                          <TableCell colSpan={4} align="center">
                            <CircularProgress/>
                          </TableCell>
                        </TableRow>
                      }
                      {visibleQuestions && visibleQuestions.length > 0 &&
                        visibleQuestions.map((item) => (
                          <TableRow key={item.questionId}
                          onClick={() => handleSubmit(item.questionId)}
                          sx={{cursor:'pointer', 
                            '&:hover':{backgroundColor: 'rgba(0, 0, 0, 0.1)'}, 
                          }}>
                            <TableCell>
                            <Chip label={item.grade} 
                                  sx={{height:'25px', 
                                       backgroundColor:'rgb(19, 130, 117)', 
                                       color: 'rgb(255, 255, 255)', 
                                       marginTop: '1px', 
                                       paddingBottom:'-5px'}}
                            />
                            </TableCell>
                            <TableCell align="left">{item.saQuestion == 1 ? "SYSTEM" : item.uploadType == 0 ? "SELF" : "TUITION CENTER "}</TableCell>
                            <TableCell>{item.title}</TableCell>
                            <TableCell>
                              {item.topic !== "-" ? (
                                <Typography>
                                  {item.isLongText}
                                  {item.isExpanded || !item.isLongText ? item.topic : item.words.slice(0, 10).join(" ") + "..."}
                                  {item.isLongText && (
                                    <span onClick={() => toggleExpanded(item)} style={{ display: 'block', margin: 0 }} className="showMoreLess">
                                      {item.isExpanded ? " Show Less" : " Show More"}
                                    </span>
                                  )}
                                </Typography>
                              ) : (
                                <Box component="span" sx={{ cursor: 'pointer' }}>
                                  <img
                                    src={`data:image/png;base64,${item.firstImage}`}
                                    alt={item.title}
                                    style={{ width: '2rem' }}
                                    onClick={() => {
                                      const byteCharacters = atob(item.firstImage);
                                      const byteNumbers = new Array(byteCharacters.length);

                                      for (let i = 0; i < byteCharacters.length; i++) {
                                        byteNumbers[i] = byteCharacters.charCodeAt(i);
                                      }

                                      const byteArray = new Uint8Array(byteNumbers);
                                      const blob = new Blob([byteArray], { type: 'image/png' });  // Create Blob from binary data
                                      const blobUrl = URL.createObjectURL(blob);

                                      window.open(blobUrl, '_blank');
                                    }}
                                  />
                                </Box>
                              )}
                            </TableCell>
                            {/* <TableCell> {/* Added 'Select' button for each row *\/}
                              <LoadingButton
                                onClick={() => handleSubmit(item.questionId)} // Pass questionId to handleSubmit
                                loading={isSubmitting}
                                variant="contained"
                              >
                                <span>Select</span>
                              </LoadingButton>
                            </TableCell> */}
                          </TableRow>
                        ))
                      }
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </Grid>
          </Grid>
          <PageQuestion
            open={popQuestionOpen}
            onClose={popQuestionClose}
            submitted={setSubmitted}
          />
        </Box>
      </Dialog>
    </>
  );

}
