import React, { useEffect, useState } from "react";
import { Box, Button, CircularProgress } from '@mui/material';
import '../../App.css';
import './sentence-transform-exercise.css';
import { AppHelper } from "../../AppHelper";
import SentenceTransformExerciseView from "./sentence-transform-exercise-view";
import SentenceTransformView from "./sentence-transform-view";

export default function SentenceTransformQSetExercise({oUser, question, submitDone, isAnsweredQ}) {
	const [records, setRecords] = useState([]);
	const [viewMode, setViewMode] = useState('GO2SET');
	const [inputValues, setInputValues] = useState([]);
	const [questionSubmitted, setQuestionSubmitted] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const [exercises, setExercises] = useState([]);
	const [exerciseId, setExerciseId] = useState("");
	const [questionSetId, setQuestionSetId] = useState("");
	
	const onViewDone = ()=>{
		//setRefreshList(!refreshList);		
		setViewMode('NEW');
	  }

	useEffect(() => {
		const fetchQSet = async () => {
			if (!question || !oUser) return;
			setIsLoading(true);
			const response = await AppHelper.apiPost('api/exercise/sentenceTransform/questionSet/get', {
				token: oUser.token,
				questionSetId: question.questionSetId
			});
			setIsLoading(false);
			if (!response.status) {
				alert('Failed to fetch the question set data!');
				return;
			}
	
			const transformedQuestions = response.data.map(item => ({
				questionNo: item.questionOrder, 
				questionId: item.questionSetItemId,
				question: transformToExerciseFormat(item.question, item.answer).replace(/<p>/g, '').replace(/<\/p>/g, ''),
				originalSentence: item.question,
				status: item.status,
				original_sentence: item.question, 
				correctedAnswer: transformAnswerForCorrection(item.answer)
			}));
			setRecords(transformedQuestions);
			if (isAnsweredQ == true) {
				generateExercises(transformedQuestions);
			} else {
				generateOriExercises(transformedQuestions);
			}
		};
		fetchQSet();
	}, [question, oUser]);
	

	useEffect(()=>{
		if(viewMode == 'SUCCESS'){
			// setTimeout(() => {
			// 	setViewMode('FINISH');
			// }, 1000*2);
		}
	}, [viewMode]);

	function transformAnswerForCorrection(answer) {
		const doc = new DOMParser().parseFromString(answer, 'text/html');
		const codeElements = doc.querySelectorAll('code');
		let correctedAnswer = [];
		codeElements.forEach((elem, index) => {
			// const tagContent = elem.innerHTML;
			correctedAnswer.push(`<a>${elem.innerHTML}</a>`);
		});
		// correctedAnswer.replace(/<\/?p>/g, ''); // Remove <p> tags if unnecessary
		return correctedAnswer.join(' ');
	}
	
	

	function transformToExerciseFormat(question, answer) {
		const doc = new DOMParser().parseFromString(answer, 'text/html');
		const textWithUnderlines = doc.body.innerHTML.replace(/<code>/g, '<u>').replace(/<\/code>/g, '</u>');
		return textWithUnderlines;
	}
const generateExercises = async (questions) => {
    setIsLoading(true);
    try {
        const response = await AppHelper.apiPost('api/exercise/sentenceTransform/exerciseGenerate', {
            token: oUser.token,
            questions: questions,
			count: 1
        });

        if (response.status && response.data) {
			// const generatedExercises = response.data.questions.reduce((acc, current) => {
			// 	if (!acc.some(item => item.question_number_reference === current.question_number_reference)) {
			// 		acc.push(current);
			// 	}
			// 	return acc;
			// }, []);
            setExercises(response.data.questions);
            console.log("Exercises Generated:", response.data.questions);
        } else {
			setExercises([]);
            alert('Failed to generate exercises');
            console.error('Failed to generate:', response);
        }
        setIsLoading(false);
    } catch (err) {
		setExercises([]);
        setIsLoading(false);
        alert('An error occurred while generating exercises');
        console.error("Error Generate:", err);
    }
};

const generateOriExercises = async (questions) => {
    setIsLoading(true);
    // try {
	console.log("hi", questions);
	const exercises = questions.map((q) => ({
		question_number_reference: q.questionNo, 
		original_sentence: q.original_sentence, 
		question: q.question, 
		exerciseOrder: q.questionNo - 1
	}))
        // const response = await AppHelper.apiPost('api/exercise/sentenceTransform/exerciseGenerate', {
        //     token: oUser.token,
        //     questions: questions,
		// 	count: 1
        // });

        // if (response.status && response.data) {
		// 	const generatedExercises = response.data.questions.reduce((acc, current) => {
		// 		if (!acc.some(item => item.question_number_reference === current.question_number_reference)) {
		// 			acc.push(current);
		// 		}
		// 		return acc;
		// 	}, []);
        //     setExercises(response.data.questions);
		// 	// setIsLoading(false);
        //     console.log("Exercises Generated:", response.data.questions);
        // } else {
		// 	setExercises([]);
        //     alert('Failed to generate exercises');
        //     console.error('Failed to generate:', response);
        // }
        setExercises(exercises);
        setIsLoading(false);
    // } catch (err) {
	// 	setExercises([]);
    //     setIsLoading(false);
    //     alert('An error occurred while generating exercises');
    //     console.error("Error Generate:", err);
    // }
};

const renderEditableText = (htmlString, listIndex) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, 'text/html');
    return Array.from(doc.body.childNodes).map((node, nodeIndex) => {
        if (node.nodeName === 'U') {
            return (
                <input
					autocomplete="off" 
					autocorrect="off" 
					autocapitalize="off" 
					spellcheck="false"
                    key={`${listIndex}-${nodeIndex}`}
                    type="text"
                    className="txt-exercise"
                    // value={inputValues[listIndex] && inputValues[listIndex][nodeIndex] ? inputValues[listIndex][nodeIndex] : ''}
                    onChange={(e) => handleInputChange(listIndex, nodeIndex, e.target.value)}
                    style={{ margin: '0 5px', backgroundColor: 'transparent' }}
                />
            );
        } else {
            return <span key={`${listIndex}-${nodeIndex}`}>{node.textContent || node.outerHTML}</span>;
        }
    });
};

const handleInputChange = (listIndex, inputIndex, value) => {
    const updatedInputValues = [...inputValues];
    if (!updatedInputValues[listIndex]) {
        updatedInputValues[listIndex] = [];
    }
    updatedInputValues[listIndex][inputIndex] = value;
    setInputValues(updatedInputValues);
};

const handleSubmit = async () => {
	
    let isAnyEmpty = false;
	let allQuestionSubmitted = false;

    const questionIdMap = {};
    records.forEach(item => {
        questionIdMap[item.questionNo] = item.questionId;
    });
    const transformedExercises = exercises.map((rec, listIndex) => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(rec.question, 'text/html');
        const elements = Array.from(doc.body.childNodes);

        const updatedStr = elements.map((node, nodeIndex) => {
            if (node.nodeName === 'U') {
                if (!inputValues[listIndex][nodeIndex]) {
                    isAnyEmpty = true; 
                }
                return `<u>${inputValues[listIndex][nodeIndex]}</u>`;
            } else {
                return node.textContent;
            }
        }).join('');

		// If user input a '.', then don't append the . from the original question set. 
		const finalStr = updatedStr.replace(/<u>(.*?)<\/u>(\s*\.)/g, (match, p1, p2) => {
			return p1.trim().endsWith('.') ? `<u>${p1}</u>` : match;
		})

        return {
            originalSentence: rec.original_sentence,
            question: finalStr,
            correctedAnswer: rec.question, 
            status: rec.question === updatedStr ? 1 : 3,
            userInput: inputValues[listIndex],
        };
    });
	console.log("transformedExercises", transformedExercises);

    if (isAnyEmpty) {
        alert('Please fill all the blanks!');
        return;
    }
    setViewMode('IS-LOADING');

	const exercisesPayload = [];
	for (var i = 0;i < transformedExercises.length;i++) {
		const exercise = transformedExercises[i];
		exercise.status = 1;
		exercisesPayload.push({
			question:exercise.question,
			originalSentence:exercise.originalSentence,
			correctedAnswer:exercise.correctedAnswer,
		});       
    }
	console.log("exercisesPayload", exercisesPayload);
	
	var newExerciseId = 0;
	try {
		const response = await AppHelper.apiPost('api/exercise/sentenceTransform/exerciseSubmitNewByQuestionSet', {
			token: oUser.token,			
			exercises: exercisesPayload, 		
			questionSetId: question.questionSetId
		});
		if (!response.status) {
			alert('Submission failed!');
			return;
		} else {
			newExerciseId = response.data.exerciseId;
			setExerciseId(response.data.exerciseId);
			setQuestionSetId(question.questionSetId);
			allQuestionSubmitted = true;
		}
	} catch (error) {
		console.error('Error submitting exercise:', error);
		alert('An error occurred while submitting an exercise');
		setViewMode('GO2SET');
		return;
	}
	
    if (allQuestionSubmitted) {
		console.log("generatedExercises", transformedExercises);		
        setQuestionSubmitted({ generatedExercises: transformedExercises, exerciseY: transformedExercises.filter(x => x.status === 1).length });
        // submitDone(transformedExercises, question.questionSetId);
		submitDone({ code: 'NEW', argId: newExerciseId });
    }
};
	return(
		<>
    <Box sx={{ flexGrow: 1, backgroundColor:'#fff' }}>
			{viewMode == 'GO2SET' && 
			<div style={{paddingTop:'.3rem'}}>
				{!isLoading && inputValues &&
				<div id="boxExercise">
					<div style={{
						fontSize:'1.3rem', 
						fontWeight:'bold', 
						display: 'flex', 
						textAlign: 'center', 
						justifyContent: 'center', 
						marginBottom:'.7rem', 
						color:'rgba(0,0,0)'}}>
						{question.questionSet.questionSetName}
					</div>
					{exercises.map((rec,iRec)=>(
						<div key={'exercise_new_' + iRec} style={{fontWeight:'normal', marginBottom:'.5rem'}}>
							<div><span style={{fontWeight:'bold'}}>Q: </span>{rec.original_sentence}</div>
							<div>
								<span style={{fontWeight:'bold'}}>A: </span>{renderEditableText(rec.question, iRec)}
							</div>
							<br />
						</div>
					))}
					<div>
						<Button className="app-btn-bg" sx={{
							margin:'1rem 0 0 0', 
							color: "white", 
							variant: "contained",  
							size: "large"
							}}
							onClick={handleSubmit} 
							>
							Submit
						</Button>
					</div>
				</div>
				}
				{ isLoading &&
				<div style={{margin:'1rem'}}>
					<CircularProgress />
				</div>
				}
			</div>
			}
			{viewMode == 'IS-LOADING' &&
			<div style={{margin:'1rem'}}>
				<CircularProgress />
			</div>
			}
		</Box>
		</>
	)
}