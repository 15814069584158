import React, { useEffect, useState, useRef } from "react";
import { Navigate, useNavigate } from 'react-router-dom';
import '../../App.css';
import {
	Avatar, Button, Box, Card, CircularProgress, FormControl, Grid, IconButton, Input, InputLabel, LinearProgress,
	Menu, MenuItem, Paper, 
	Select, Stack, Switch, TextField, Typography, FormControlLabel, Checkbox,
	imageListClasses
} from '@mui/material';
import { AppHelper } from "../../AppHelper";
import { EssayHelper } from '../../helpers/essay.helper';
import UploadIcon from '@mui/icons-material/Upload';
import CloseIcon from '@mui/icons-material/Close';
import SentenceTransformImages from "./sentence-transform-images";
import SentenceTransformQSetExercise from "./sentence-transform-qset-exercise";
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import PreviewIcon from '@mui/icons-material/Preview';
import PopPreview from './sentence-transform-qset-pop-preview';

export default function SentenceTransformForm({oUser, submitDone}) {
	const [record, setRecord] = useState({});
	const [isLoading, setIsLoading] = useState(false);
	const [selectedImages, setSelectedImages] = useState([]);
	const [vMode, setVMode] = React.useState('NEW');
	const [invalidImg, setInvalidImg] = useState(false);
	const [startQuestion, setStartQuestion] = useState(false);
	const [gradeList, setGradeList] = useState(oUser.grade);
	const [groupedQuestions, setGroupedQuestions] = useState({});
	const [selectedQuestionSet, setSelectedQuestionSet] = useState({
		questionSetId: '', 
		questionSet: ''
	})
	const [index, setIndex] = useState("");
	// const [questionSetName, setQuestionSetName] = useState("");
	// const [questionSetId, setQuestionSetId] = useState("");
	const[questionItem, setQuestionItem] = useState("");
	const [recList, setRecList] = useState([]);
	const hasLoadedRef = useRef(false);
	const [popPreview, setPopPreview] = useState(false);
	const [showBack, setShowBack] = useState(false);
	const [showNext, setShowNext] = useState(false);
	const [isAnsweredQ, setIsAnsweredQ] = useState(false);
	const buttonVisibiltiy = {
		'true': 'visible', 
		'false': 'hidden'
	}


  useEffect(() => {
		setRecord({});
		fetchQuestionSets();
  }, [oUser]);

  useEffect(() => {
	if (!hasLoadedRef.current && oUser) {
	  loadList();
	  hasLoadedRef.current = true;
	}
  }, [oUser]);
  

	const handleFileChange = (event) => {
		if(event.target.files.length<1) return;
		var _files = [];
		for(var i=0; i<event.target.files.length; i++){
			_files.push({
				file: event.target.files[i],
				url: URL.createObjectURL(event.target.files[i])
			});
		}
		setSelectedImages(_files);
  };

  const handleUploadClick = () => {
    const inputImg = document.getElementById("file-input"); // Trigger file input
	inputImg.value = "";
	inputImg.click();
  };
  const handleRemoveClick = (index) =>{
	setSelectedImages(selectedImages.filter((_, i) => i !== index));
  };
	const file2B64 = async(argFile)=>{
		try {
			const reader = new FileReader();
			reader.readAsDataURL(argFile);
			const isHeicFile = argFile.name.toLowerCase().endsWith('.heic');
			const isCropped = false;
			if (isCropped) {//-- disabled crop fun for now (original code in scan-popup-v1)
			}
			else{
				await new Promise((resolveReader, rejectReader) => {
					reader.onload = () => {
						resolveReader();
					};
					reader.onerror = (error) => {
						rejectReader(error);
					};
				})
				const base64 = reader.result.split(",")[1];
				return { b64: base64, fileType:argFile.type, fileSize:argFile.size, isHeicFile:isHeicFile, align:false };
			}
		}
		catch (error) {
			console.error("Error converting image to base64:", error);
			return null;
		}
	}
	const onSubmit = async ()=>{
		if(!oUser){
			alert('Invalid user!');
			return;
		}
		for(var i=0; i<selectedImages.length; i++){
			var _img = selectedImages[i];
			_img.b64 = (await file2B64(_img.file)).b64;
		}
		const totalSize = selectedImages.reduce((acc, x) => {
			const base64String = x.b64;
			const sizeB = new Buffer(base64String, "base64").length;
			const sizeMB = (sizeB / (1024 * 1024)).toFixed(2);
			return acc + sizeMB;
		}, 0);
		console.log("size MB", totalSize);
		if (totalSize > 30) {
			alert(
				"Total image size exceeds 30 MB. Please reduce the number or size of images."
			);
			setIsLoading(false); // Set loading state back to false
			return;
		}

		setIsLoading(true); // Set loading state to true
		const _imgArr = selectedImages.map((page) =>
			page.align ? page.alignB64 : page.b64
		);
		setVMode('SUBMITTING');
		
		var _res = await AppHelper.apiPost('api/exercise/sentenceTransform/transcribe', { token: oUser.token, images: _imgArr, profileId: oUser.profileId });
		if(_res.status){
			setRecord({exerciseId: _res.data.exerciseId});
			pageStatusCheck({exerciseId: _res.data.exerciseId});
		}
		AppHelper.apiAuditPost("EX-ADD", "");
	}

	const pageStatusCheck = async (argRec) => {
		var _res = await AppHelper.apiPost('api/exercise/sentenceTransform/get', {
			token: oUser.token,
			profileId: oUser.profileId,
			exerciseId: argRec.exerciseId
		});
		if (!_res.status) {
			console.error('API call failed:', _res);
			alert('ERROR');
			return;
		}
		var _rec = _res.data;
		setRecord(_rec);

		const pageCount = _rec.pageCount;
		const validImagesCount = _rec.pages.filter(page => page.status === 1).length;
		const invalidImagesCount = _rec.pages.filter(page => page.status === 6).length;

		// if (invalidImagesCount >= 1) {
		// 	setInvalidImg(true);
		// }
		
		if(_rec.pages.find(x=>x.status == 0)){
			setVMode('PROCESSING');
			setTimeout(() => {
				pageStatusCheck(_rec);
			}, 1000*3);
		}
		else if (invalidImagesCount ===  pageCount) {
			alert('Transcription failed for all images. Please try again.');
			setVMode('ERROR');
			window.location.reload();
		}
		else {
			console.log('all done *****');
			setVMode('DONE');
			submitDone({ code: 'NEW', argId: argRec.exerciseId });
		}

	}

	const openImageInNewTab = (blobUrl) => {
		window.open(blobUrl, "_blank", "noopener,noreferrer");
	};

	const handleGradeChange = (event) =>{
		setGroupedQuestions([]);
		setGradeList(event.target.value); 
		// fetchQuestionSets();
	}

	useEffect(() => {
		fetchQuestionSets();
	}, [gradeList]);

    const fetchQuestionSets = async () => {
		if (!gradeList) {
			return;
		}
        setIsLoading(true);
		setSelectedQuestionSet({
			questionSetId: '', 
			questionSet: ''
		})
        try {
            const response = await AppHelper.apiPost('api/exercise/sentenceTransform/questionSet/getByGrade', {
				token: oUser.token, 
				grade: gradeList
			  });
            if (response?.data.length == 0) {
                console.error("Failed to fetch question sets.");
                setGroupedQuestions([]);
            } else {
				const sortedGroupQuestion = response.data.sort((a, b) => 
				a.questionSetName.toLowerCase().localeCompare(b.questionSetName.toLowerCase(), 
																undefined, 
																{numeric: true, sensitivity: 'base'}))
                setGroupedQuestions(sortedGroupQuestion);
            }
        } catch (error) {
            console.error("Error fetching question sets:", error);
        } finally {
            setIsLoading(false);
        }
    };

	const handleQuestionSetChange = (event) => {
		const [selectedId, index] = event.target.value.split(':');
		setIndex(index);
		if (event.target.value == 'No Question'){
			return;
		}
		const selectedSet = groupedQuestions[index];
		if (selectedSet == null){
			return;
		}
		setSelectedQuestionSet({
			questionSetId: selectedId, 
			questionSet: selectedSet
		});
		setPopPreview(true);
		console.log("selectedSet", selectedSet);
    };

	const fetchQuestionItem = async (arg) => {
		setIsLoading(true);
        try {
            const response = await AppHelper.apiPost('api/exercise/sentenceTransform/questionSet/get', {
				token: oUser.token, 
				questionSetId: arg.questionSetId
			  });
            if (response?.data == []) {
                console.error("Failed to fetch question sets.");
                setQuestionItem([]);
            } else {
                setQuestionItem(response.data);
            }
        } catch (error) {
            console.error("Error fetching question sets:", error);
        } finally {
            setIsLoading(false);
			// console.log("questionItem", questionItem);
        }
	}

	useEffect(() => {
		index <= 0 
			? setShowBack(false) 
			: setShowBack(true);
		index >= groupedQuestions.length - 1 
			? setShowNext(false) 
			: setShowNext(true);
	}, [selectedQuestionSet]);

	const handleStartClick = () => {
		if (selectedQuestionSet.questionSetId == ''){
			alert("Please select a question set. ");
			return;
		}
		if (recList.some(r => r.questionSetId === selectedQuestionSet.questionSetId)) {
			setIsAnsweredQ(true);
		}
		setStartQuestion(true);
		// fetchQuestionItem(selectedQuestionSet);
		setVMode("GO2SET");
	};

	const handleBackClick = () => {
		if (index == ''){
			return;
		}
		const newIdx = index - 1;
		if (newIdx <= -1) {
			return;
		};
		setIndex(newIdx);
		const prevGrpId = groupedQuestions[newIdx]?.questionSetId;
		handleQuestionSetChange({target:  { value: `${prevGrpId}:${newIdx}`}});
	};

	const handleNextClick = () => {
		if (index == ''){
			return;
		}
		console.log("Next", index);
		let newIdx = Number(index) + 1;
		if (newIdx >= groupedQuestions.length) {
			return;
		};
		setIndex(newIdx);
		const nextGrpId = groupedQuestions[newIdx]?.questionSetId;
		handleQuestionSetChange({target:  { value: `${nextGrpId}:${newIdx}`}});
	};

	const loadList = async (argExerciseId=null) => { 
		setIsLoading(true); 
		var _res = await AppHelper.apiPost('api/exercise/sentenceTransform/list', 
			{token: oUser.token, profileId: oUser.profileId}); 
			if(!_res || !_res.status){ 
				setRecList([]); 
				console.log('set empty []', _res); 
				return; 
			} 
			var _list = _res.data; 
			setRecList(_list); 
			console.log('setRecList', _list); 
		};
		
	const onOpenPreview = () => {
		setPopPreview(true);
	}

	const onClosePreview = () => {
		setPopPreview(false);
	}


	return(
		<>
    <Box sx={{ flexGrow: 1, backgroundColor:'#fff' }}>
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Box sx={{fontWeight:'bold', fontFamily: "'Playpen Sans', cursive", fontSize:'2rem', textAlign:'center'}}>
                    Sentence Transformation
                </Box>
            </Grid>
            {vMode === 'NEW' &&
                <>
                <Grid item xs={12} style={{ textAlign:'center', marginTop: "0"}}>
                    <Typography sx={{fontSize:'1.3rem'}}>Select An Exercise</Typography>
                </Grid>
                <Grid container item xs={12} spacing={2} justifyContent="center">
                    <Grid item xs={12} md={6}>
						<Grid container spacing={2}>
							<Grid item xs={12}>
							<FormControl fullWidth>
								<InputLabel>Grade</InputLabel>
								<Select
									value={gradeList}
									label="Grade"
									onChange={handleGradeChange}
								>
									<MenuItem value={'P1'}>Primary 1</MenuItem>
									<MenuItem value={'P2'}>Primary 2</MenuItem>
									<MenuItem value={'P3'}>Primary 3</MenuItem>
									<MenuItem value={'P4'}>Primary 4</MenuItem>
									<MenuItem value={'P5'}>Primary 5</MenuItem>
									<MenuItem value={'P6'}>Primary 6</MenuItem>
								</Select>
							</FormControl>
							</Grid>
							<Grid item xs={12}>
							<FormControl fullWidth>
								<InputLabel>Question Set Name</InputLabel>
								<Select
									value={selectedQuestionSet.questionSetId 
											? `${selectedQuestionSet.questionSetId}:${index}` 
											: ""}
									label="Question Set Name"
									onChange={handleQuestionSetChange}>
									{!isLoading && groupedQuestions && groupedQuestions.length > 0 ? (
									groupedQuestions.map((q, index) => (
									<MenuItem key={q.questionSetId} value={`${q.questionSetId}:${index}`}>
									<Box sx={{ display: 'flex', alignItems: 'center' }}>
										<Typography variant="body1">{q.questionSetName}</Typography>
										{recList.some(r => r.questionSetId === q.questionSetId) && (
										<Box
											sx={{
											backgroundColor: 'green',
											borderRadius: '50%',
											width: 24,
											height: 24,
											display: 'flex',
											alignItems: 'center',
											justifyContent: 'center',
											ml: 1
											}}
										>
											<CheckRoundedIcon sx={{ color: 'white', fontSize: 16 }} />
										</Box>
										)}
									</Box>
									</MenuItem>
									))
									) : (
									<MenuItem value="No Question">No Question Available</MenuItem>
									)}
								</Select>
							</FormControl>
							</Grid>
						
							{selectedQuestionSet.questionSetId.length != 0 &&
							<>
							<Grid
							container
							direction="row"
							alignItems="center"
							justifyContent="center"
							sx={{marginTop: '20px'}}
							>
							  <Grid item>
								<Button
								className="app-btn-bg"
								sx={{
									margin: '0 5px 22px 5px',
									width: '100px',
									visibility: buttonVisibiltiy[showBack]
								}}
								onClick={handleBackClick}
								color="info"
								variant="contained"
								size="large"
								>
								BACK
								</Button>
							  </Grid>
							  <Grid item>
								<Button
								className="app-btn-bg"
								sx={{
									margin: '0 5px 22px 5px',
									width: '100px'
								}}
								onClick={handleStartClick}
								color="info"
								variant="contained"
								size="large"
								>
								START
								</Button>
							  </Grid>
							  <Grid item>
								<Button
								className="app-btn-bg"
								sx={{
									margin: '0 5px 22px 5px',
									width: '100px',
									visibility: buttonVisibiltiy[showNext]
								}}
								onClick={handleNextClick}
								color="info"
								variant="contained"
								size="large"
								>
								NEXT
								</Button>
							  </Grid>
							</Grid>
							</>
							}
						</Grid>
                    </Grid>

					{/* Right side for preview */}
					<Grid item xs={12} md={6}>
							{selectedQuestionSet.questionSetId.length != 0 ? (	
								<Paper elevation={3}
									sx={{minHeight: 400, 
										padding:2, 
										display: 'flex', 
										alignItems: 'left', 
										justifyContent: 'left'
								}}>
								  <PopPreview
									open={popPreview}
									onClose={onClosePreview}
									question={selectedQuestionSet}
								  />
								</Paper>
							) : (	
								<Paper elevation={3}
								sx={{minHeight: 95, 
									padding:2, 
									display: 'flex', 
									alignItems: 'center', 
									justifyContent: 'center'
								}}>
									<>Question Set preview will be shown here. </>
								</Paper>
							)}
					</Grid>
            	</Grid>


			<Grid item xs={12} style={{ display:'flex', textAlign: "center", marginTop: "1rem", justifyContent:'center', alignItems: 'center' }}>
				{/* following code needless after change layout to show preview on the right side of page. 
				can delete if nothing wrong with the new UI. */}
				{/* {selectedQuestionSet.questionSetId.length != 0 &&
					<Button className="app-btn-bg" sx={{
						margin:'0 5px 22px 5px',
						width: '100px'
						}}
						onClick={onOpenPreview} color="info" variant="contained" size="large">
						PREVIEW
					</Button>
				} */}
				{/* <Button className="app-btn-bg" sx={{
					margin:'0 5px 22px 5px',
						width: '100px'
					}}
					onClick={handleStartClick} color="info" variant="contained" size="large">
					START
				</Button> */}
			</Grid>


			<table style={{ width: "100%", marginBottom: "-50px" }}>
				<td style={{ textAlign: "right", width: "46.5%"}}>
				<hr style={{ width: "50px", display: "inline-block" }} />
				</td>
				<td style={{ textAlign: "center", whiteSpace: "nowrap" }}>OR</td>
				<td style={{ textAlign: "left", width: "45%"}}>
					<hr style={{ width: "50px", display: "inline-block" }} />
				</td>
			</table>



				<Grid container item xs={12} justifyContent="center" style={{marginTop: "3rem"}}>
					<div style={{ display:'flex', justifyContent:'center', alignItems: 'center', width: '100%'}}>
						<IconButton
							onClick={handleUploadClick}
							style={{
								width: "80px",
								height: "80px",
								backgroundColor: "rgba(19, 130, 117, 1)",
								color: "white",
								borderRadius: "50%",
								boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
							}}
						>
							<UploadIcon style={{ fontSize: "40px" }} />
						</IconButton>
					</div>
					<div style={{ display:'flex', textAlign: "center", marginTop: "1rem", justifyContent:'center' }}>
						{/* Image Preview */}
						{selectedImages.length == 0 &&
							<div>
							<Box sx={{fontWeight:'normal', textAlign:'center', marginBottom:'1rem'}}>
								<Typography sx={{fontSize:'1.3rem'}}>Upload the photo(s) of your handwritten or printed questions and answers.</Typography>
								<Typography sx={{fontSize:'1.3rem'}}>Below is the sample photo.</Typography>
							</Box>
							<img src="../sample-image.png" alt="Sample" 
								style={{
										maxWidth: "100%", 
										height: "20rem",
										objectFit: "cover",
										marginRight: "5px",
										borderRadius: "10px",
										border: "2px solid #ccc",
										borderRadius:'5%'
									}} 
									onClick={() => openImageInNewTab("../sample-image.png")}/>
							</div>
						}
						{selectedImages && selectedImages.map((img, iImg)=>(
							<div key={`boxImg${iImg}`} style={{position: 'relative', margin: '5px', width: "200px", height: "200px"}}>
								<img
									src={img.url}
									alt="Preview"
									style={{
										width: "200px",
										height: "200px",
										objectFit: "cover",
										marginRight: "5px",
										borderRadius: "10px",
										border: "2px solid #ccc",
										borderRadius:'5%'
									}}
								/>
								<IconButton
									onClick={() => handleRemoveClick(iImg)}
									style={{
										width: "25px", 
										height: "25px",
										position: 'absolute',
										top: 0, 
										right: 0, 
										color: 'rgba(255, 255, 255, 0.8)', 
										backgroundColor: 'rgba(0, 0, 0, 0.6)', 
										margin: '5px', 
										'&:hover': {
											backgroundColor: 'rgba(0, 0, 0, 0.8)',
										},
									}}>
										<CloseIcon />
									</IconButton>
							</div>
						))}

						<input
							id="file-input"
							type="file"
							accept="image/*"
							style={{ display: "none" }}
							onChange={handleFileChange}
							multiple
						/>
					</div>
					{ selectedImages && selectedImages.length>0 && 
					<Grid item xs={12} style={{ display:'flex', textAlign: "center", marginTop: "1rem", justifyContent:'center', alignItems: 'center' }}>
						<Button className="app-btn-bg" sx={{
							margin:'0 5px 22px 5px'
							}}
							onClick={onSubmit} color="info" variant="contained" size="large">
							Submit
						</Button>
					</Grid>
					}
				</Grid>
				</>
				}
				{ (vMode == 'PROCESSING' || vMode == 'DONE') &&
				<>
				<Grid item xs={12}>
          <LinearProgress color="info" sx={{ marginTop: '1rem' }} />
				</Grid>
				<Grid item xs={12} mt={5} align={'center'}>
					{ record && record.pages && record.pages.map((p, iP)=>(
						<Box display={'flex'} alignItems={'center'} justifyContent={'center'} key={'img_i_' + iP}>
                {p.status !== 1 &&
                  <CircularProgress />
                }
                {p.status === 1 &&
                  (
                    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
                      <CircularProgress variant="determinate" value={100} color="success" />
                      <Box
                        sx={{
                          top: 0, left: 0, bottom: 0, right: 0, position: 'absolute',
                          display: 'flex', alignItems: 'center', justifyContent: 'center',
                        }}
                      >
                        <Typography variant="caption" component="div" color="text.info">{`100%`}</Typography>
                      </Box>
                    </Box>
                  )
                }
                <Typography sx={{ marginLeft: '10px' }}>Page {iP+1}</Typography>
              </Box>
						// <div >
						// 	 {iP+1} : {p.status == 1 ? 'DONE' : 'Processing'}
						// </div>
					))}
				</Grid>
				</>
				}
				{ vMode == 'SUBMITTING' &&
				<>
				<Grid item xs={12}>
          <LinearProgress color="info" sx={{ marginTop: '1rem' }} />
				</Grid>
				<Grid item xs={12} mt={5} align={'center'}>
					<CircularProgress />
				</Grid>
				</>
				}
				{ vMode == 'GO2SET' &&
					<SentenceTransformQSetExercise 
					question={selectedQuestionSet}
					oUser={oUser} 
					submitDone={submitDone}
					isAnsweredQ={isAnsweredQ}
					/>
				}
			</Grid>
			{/* <PopPreview
				open={popPreview}
				onClose={onClosePreview}
				question={selectedQuestionSet}
			/> */}
		</Box>
		</>
	)
}